body {
    background: #eee !important;
    padding-top: 56px;
}

#map {
    height: calc(100vh - 331px - 24px);
}

#hero {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 56px - 96px);
    justify-content: center;
    text-align: center;
}

.concertBackground {
    background-image: url("/images/guitar.jpeg");
    background-size: cover;
    background-position: bottom;
}

.bg-black {
    background: #000 !important;
}

.table td {
    vertical-align: middle !important;
}

.display-4 {
    font-size: 2rem !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #c00;
    border: #c00;
}
.CalendarDay__selected_span {
    background: #333;
    border: #333;
}
.CalendarDay__hovered_span {
    background: #555;
    border: #555;
    color: white;
}
.CalendarDay__hovered_span:hover {
    background: #333;
    border: #333;
    color: white;
}
.CalendarDay__selected_span:hover {
    background: gray;
    border: gray;
}

.DateInput_input__focused {
    border-bottom: 2px solid #c00;
}
