#fullscreen-nav{
    background: rgba(0,0,0,.9);
    position: fixed;
    top:0;
    left:0;
    height: 100vh;
    width: 0;
    z-index: 2000;
    -webkit-transition: width .3s;
    transition: width .3s;
    overflow: hidden;
}

#fullscreen-nav div a:hover{
    color: #666;
}

#fullscreen-nav div{
    background: white;
    width: 75vw !important;
    height: 100vh;
    display:flex;
    flex-direction: column;
    text-align: center;
    justify-content:center;
    z-index: 1900;
}

#fullscreen-nav div a{
    z-index: 1000;
    text-decoration: none;
    -webkit-transition: all .3s;
    transition: all .3s;
    color:#333;
}

.fullscreen-nav-expanded{
    width: 100vw !important;
}

.active-fullscreen-link:hover{color: #cc0000!important;}

#brand{
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
}

.hidden{
    /* visibility: hidden; */
    opacity: 0;
}

.visible{
    /* visibility: visible !important; */
    opacity: 1;
}

.navbar-toggler{
    border: 0 !important;
}
.navbar-toggler-icon{
    color:white !important;
}
.no-scroll{
    overflow: hidden;
}

.display-ors {
    font-size: 3rem;
    font-weight: 300;
    line-height: 1.0;
}

@media (min-width: 576px) {
    .display-ors {
        font-size: 4.5rem;
    }
}
body {
    background: #eee !important;
    padding-top: 56px;
}

#map {
    height: calc(100vh - 331px - 24px);
}

#hero {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 56px - 96px);
    justify-content: center;
    text-align: center;
}

.concertBackground {
    background-image: url("/images/guitar.jpeg");
    background-size: cover;
    background-position: bottom;
}

.bg-black {
    background: #000 !important;
}

.table td {
    vertical-align: middle !important;
}

.display-4 {
    font-size: 2rem !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #c00;
    border: #c00;
}
.CalendarDay__selected_span {
    background: #333;
    border: #333;
}
.CalendarDay__hovered_span {
    background: #555;
    border: #555;
    color: white;
}
.CalendarDay__hovered_span:hover {
    background: #333;
    border: #333;
    color: white;
}
.CalendarDay__selected_span:hover {
    background: gray;
    border: gray;
}

.DateInput_input__focused {
    border-bottom: 2px solid #c00;
}

