#fullscreen-nav{
    background: rgba(0,0,0,.9);
    position: fixed;
    top:0;
    left:0;
    height: 100vh;
    width: 0;
    z-index: 2000;
    transition: width .3s;
    overflow: hidden;
}

#fullscreen-nav div a:hover{
    color: #666;
}

#fullscreen-nav div{
    background: white;
    width: 75vw !important;
    height: 100vh;
    display:flex;
    flex-direction: column;
    text-align: center;
    justify-content:center;
    z-index: 1900;
}

#fullscreen-nav div a{
    z-index: 1000;
    text-decoration: none;
    transition: all .3s;
    color:#333;
}

.fullscreen-nav-expanded{
    width: 100vw !important;
}

.active-fullscreen-link:hover{color: #cc0000!important;}

#brand{
    transition: opacity 1s;
}

.hidden{
    /* visibility: hidden; */
    opacity: 0;
}

.visible{
    /* visibility: visible !important; */
    opacity: 1;
}

.navbar-toggler{
    border: 0 !important;
}
.navbar-toggler-icon{
    color:white !important;
}
.no-scroll{
    overflow: hidden;
}

.display-ors {
    font-size: 3rem;
    font-weight: 300;
    line-height: 1.0;
}

@media (min-width: 576px) {
    .display-ors {
        font-size: 4.5rem;
    }
}